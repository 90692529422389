body {
  background-color: $light-grey;
  color: $dark-grey;
}

article {
  background-color: $white;
  padding: 20px;
}

#subs_list {
        width: 50%;
    }

#chart svg {
        height: 400px;
    }

.dt-button {
    background-color: #f2f2f2;
    color: #3d4a4c;
    margin: 20px 5px;
    padding: 10px;
}

.dt-button a:hover, a:visited, a:link, a:active {
    text-decoration: none;

}

.dt-button.button-unclicked {
    background-color: #f08382;

}

.dt-button:hover {
    background-color: #f08382;
}

.dataTables_filter {
    margin: 15px 0px 0px 0px;
}